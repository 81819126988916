/*
 * @Author       : zxlin
 * @Date         : 2024-03-14 16:59:48
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-15 11:14:08
 * @FilePath     : \calendar-singapore\src\main.js
 * @Description  : 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/local'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import '@/styles/index.css'
import 'amfe-flexible'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(Antd)
app.mount('#app')