export default {
  homeBannerTxt: 'Calendar is a cutting-edge high-tech enterprise dedicated to application software development and operation. With a cumulative user base exceeding 300 million, we pride ourselves on owning over 80 proprietary intellectual properties. Our achievements include securing four patents, three of which are groundbreaking inventions.',
  home: 'Home',
  team: 'Team',
  product: 'Product',
  recruitment: 'Recruitment',
  contacts: 'Contacts',
  about: 'ABOUT',
  homeUs: 'US',
  wnlTitle: 'Calendar',
  wnlRemark: 'Our flagship Calendar App, trusted by over 300 million users worldwide.',
  xdrcTitle: 'Closer',
  xdrcRemark: 'Designed to capture every precious moment shared between couples.',
  aiTitle: 'EasyArt',
  aiRemark: 'Create and transform photos into masterpieces with EasyArt.',
  dhTitle: 'Dead Spreading: Survival',
  dhRemark: 'Lead your team to break the dead wave and survive!',
  mtTitle: 'Mega Tower',
  mtRemark: 'Fight battles with Guild members & triumph over your foes in the Galaxy!',
  mt2Title: 'Mega Tower 2: Starship Voyage',
  mt2Remark: 'Explore the universe, and defend the Interstellar Journey with Mega Tower 2!',
  our: 'Our',
  products: 'Products',
  life: 'Life',
  activities: 'Activities',
  nineYears: '9th Anniversary',
  hKJourney: 'Journey to Hongkong',
  sports: 'Fitness and Leisure',
  birthdayParty: 'Birthday Celebrations',
  welcomeToJoin: 'Welcome To Join',
  Calendar: 'CALENDAR',
  administrativeExecutive: 'Administrative Executive',
  marketingExecutive: 'Marketing Executive',
  viewMore: 'View More',
  joinText1: 'Please send your resume to: ',
  joinText2: '(Email subject: Application for [Company Name] - [Position] - [Your Name])',
  joinText3: 'We look forward to having you join us!',
  address: 'Address:',
  tel: 'Tel:',
  email: 'Email:',
  contact: 'Contacts',
  us: 'Us'
}