/*
 * @Author       : zxlin
 * @Date         : 2024-03-14 16:59:48
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-15 09:20:50
 * @FilePath     : \calendar-singapore\src\router\index.js
 * @Description  : 
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutPc from '@/layout/pc'
import LayoutMobile from '@/layout/mobile'
const routes = [
  {
    path: '/',
    name: 'LayoutPc',
    component: LayoutPc,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'pcHome',
        component: () => import(/* webpackChunkName: "pcHome" */ '../views/pc/home'),
      }
    ]
  },
  {
    path: '/mobile',
    name: 'LayoutMobile',
    component: LayoutMobile,
    redirect: '/mobile/home',
    children: [
      {
        path: 'home',
        name: 'mobileHome',
        component: () => import(/* webpackChunkName: "mobileHome" */ '../views/mobile/home'),
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
