<!--
 * @Author       : zxlin
 * @Date         : 2024-03-14 17:25:56
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-15 09:37:43
 * @FilePath     : \calendar-singapore\src\layout\pc\components\FooterComponent.vue
 * @Description  : 
-->
<template>
  <div class="footer" :class="{footer_en:isEn}">
      <img class="logo" src="@/assets/imgs/footer_icon.png">
      <p class="p1">{{$t('address')}}：60 PAYA LEBAR ROAD #04-10<br/>PAYA LEBAR SQUARE SINGAPORE  409051<img src="@/assets/imgs/adress_icon.png"></p>
      <p class="p2">{{$t('tel')}}：(0065)89082426</p>
      <p class="p2">{{$t('email')}}：hr@youloft.com</p>
      <p class="p2">Copyright © 2024 YOULOFT Inc.All rights reserved.</p>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
const isEn = computed(() => {
  return locale.value === 'en';
});
</script>
<style lang="less" scoped>
.footer {
  background: #000000;
  padding: 218px 0 114px;
  margin-top: -180px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  &.footer_en {
    font-family: "SF Pro Text Regular", "arial black", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  }
  .logo {
      width: 204px;
      margin: 0 auto;
  }
  p {
      margin-bottom: 0;
      font-weight: 400;
  }
  .p1 {
      margin-top: 32px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      line-height: 32px;
      img {
          width: 24px;
          height: auto;
          margin-left: 10px;
          transform: translateY(-4px);
      }
  }
  .p2 {
      margin-top: 30px;
  }
}
</style>