<!--
 * @Author       : zxlin
 * @Date         : 2024-03-15 09:37:09
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-15 10:03:03
 * @FilePath     : \calendar-singapore\src\layout\mobile\components\NavbarComponent.vue
 * @Description  : 
-->
<template>
  <div class="header" :class="{'header2': header_type == 2}" @click="doSth">
      <img class="logo" :src="header_type == 1 ? logo0:logo1">
      <a-drawer
          v-model:open="is_open"
          class="header-drawer"
          :maskClosable="false"
      >
          <div class="list">
              <div class="item" :class="{ 'active': item.name == pageActive }" v-for="item in pageList" :key="item.name" @click="handleChangePage(item.name)">
                  <p class="title">{{ $t(item.name) }}</p>
                  <p class="xian"></p>
              </div>
          </div>

          <p class="tran">
            <span :class="{'active': locale == 'zh'}" @click="handleChangeLocal('zh')">中文</span>
            <span></span>
            <span :class="{'active': locale == 'en'}" @click="handleChangeLocal('en')">EN</span>
          </p>
      </a-drawer>
      <img class="right-icon" v-show="!is_open && header_type == 2" src="@/assets/imgs/mobile/common/menu-icon.png" @click.stop="doSth">
      <img class="right-icon" v-show="!is_open && header_type == 1" src="@/assets/imgs/mobile/common/menu-iconw.png" @click.stop="doSth">
      <img class="right-icon" v-show="is_open" src="@/assets/imgs/mobile/common/cha.png"  @click.stop="doSth">
  </div>
</template>
<script setup>
import logo0 from "@/assets/imgs/mobile/common/logo_0.png"
import logo1 from "@/assets/imgs/mobile/common/logo_1.png"
import { onMounted, ref, computed } from 'vue'
import pageList from '@/utils/pageList';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
function handleChangeLocal(lang = 'zh') {
  localStorage.setItem('calendar-lang', lang);
  locale.value = lang;
  is_open.value = false
}
const isEn = computed(() => {
  return locale.value === 'en';
});
const is_open = ref(false)

// header类型
const header_type = ref(1)
const doSth = () => {
  is_open.value = !is_open.value
  if(is_open.value || scrollState.value > 0) {
    header_type.value = 2
  } else {
    header_type.value = 1
  }
}

const scrollState = ref(0)
const pageActive = ref('home');
const height = window.innerHeight
onMounted(() => {
  window.addEventListener('scroll', function () {
    scrollState.value = window.scrollY
    header_type.value = scrollState.value > 0 ? 2 : 1

    for(let i = 0;i < pageList.length;i++) {
      const dom = document.querySelector(`#href_${pageList[i].name}`);
      if (dom) {
        let top = dom.getBoundingClientRect().top
        if(top >= 0 && top <= height / 2) {
          pageActive.value = pageList[i].name;
          break
        }
      }
    }
  })
})

const handleChangePage = (path = 'home') => {
  document.querySelector(`#href_${path}`).scrollIntoView({
    behavior: 'smooth'
  });
  is_open.value = false
  pageActive.value = path
}
// onMounted(() => {
//   nextTick(() => {
//     pageList.forEach((i) => {
//       const dom = document.querySelector(`#href_${i.name}`);
//       if (dom) {
//         let options = {
//           threshold: 0,
//         };
//         let observer = new IntersectionObserver(callback, options);
//         function callback(entries) {
//           console.log(entries[0])
//           if (entries[0].intersectionRatio > 0.5) {
//             pageActive.value = i.name;
//           }
//         }
//         observer.observe(dom);
//       }
//     });
//   });
// });
</script>
<style lang="less" scoped>
img {
  display: block;
  pointer-events: auto;
}
.header {
  width: 100%;
  height: 112px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  &.header2 {
      background-color: #fff;
  }
  .logo {
      width: 160px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
  }
  .right-icon {
      width: 34px;
      height: auto;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      pointer-events: all;
  }
}
.header-drawer {
  position: relative;
  .list {
      width: 100%;
      .item {
          width: 100%;
          height: 126px;
          font-size: 32px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 2px solid #E6E6E6;
          position: relative;
          .title {
              margin-bottom: 0;
              color: #333333;
              font-family: 'PingFang SC Semibold';
          }
          .xian {
              width: 4px;
              height: 100%;
              background-color: #F44C1C;
              position: absolute;
              top: 0;
              left: 0;
              display: none;
          }
          &.active {
              .title {
                  color: #F44C1C
              }
              .xian {
                  display: block;
              }
          }
      }
      .item:last-child {
          border-bottom: 2px solid #E6E6E6;
      }
  }
  .tran {
    margin-bottom: 0;
    position: absolute;
    bottom: 88px;
    right: 66px;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      font-weight: 600;
      font-size: 32px;
      color: rgba(51,51,51,0.2);
      line-height: 44px;
      &:nth-child(1) {
        font-family: 'PingFang SC Semibold';
      }
      &:nth-child(2) {
        width: 2px;
        height: 28px;
        background: rgba(151,151,151,0.4);
        margin: 0 30px;
      }
      &.active {
        color: #333;
      }
    }
  }
}
</style>