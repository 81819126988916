/*
 * @Author       : zxlin
 * @Date         : 2024-03-15 10:46:32
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-19 14:41:05
 * @FilePath     : \calendar-singapore\src\local\index.js
 * @Description  : 国际化
 */
import { createI18n } from 'vue-i18n'

import en from './en'
import zh from './zh'

const locale = localStorage.getItem('calendar-lang') || 'en'

const messages = {
  en,
  zh
}

const i18n = createI18n({
  legacy: true,
  allowComposition: true,
  locale,
  messages
})

export default i18n