const page = {
  home: 'home',
  product: 'product',
  team: 'team',
  recruitment: 'recruitment',
  contacts: 'contacts'
}

export default Object.keys(page).map((item) => {
  return {
    id: item,
    pathPc: `${item}`,
    pathMobile: `/mobile/${item}`,
    name: page[item],
  }
})