<!--
 * @Author       : zxlin
 * @Date         : 2024-03-15 09:37:09
 * @LastEditors  : zxlin
 * @LastEditTime : 2024-03-20 18:11:23
 * @FilePath     : \calendar-singapore\src\layout\pc\components\NavbarComponent.vue
 * @Description  : 
-->
<template>
  <div
    class="navbar no_select"
    :style="{
      backgroundColor: scrollState
        ? topTypeList[1].backgroundColor
        : topTypeList[topType].backgroundColor,
      color: scrollState ? topTypeList[1].color : topTypeList[topType].color,
      left: `${navbarLeft}px`,
    }"
  >
    <div class="left" @click="handleChangePage()">
      <img
        :src="
          require(`@/assets/imgs/logo_${
            topType === 1 ? 1 : scrollState ? 1 : 0
          }.png`)
        "
      />
    </div>
    <div class="right">
      <div
        class="page_item"
        :class="{
          page_item_active: item.pathPc === pageActive,
        }"
        :style="{
          color:
            item.pathPc === pageActive
              ? scrollState
                ? topTypeList[1].activeColor
                : topTypeList[topType].activeColor
              : undefined,
        }"
        v-for="item in pageList"
        @click="handleChangePage(item.pathPc)"
      >
        <div
          class="page_item_border"
          :style="{
            backgroundColor:
              item.pathPc === pageActive
                ? scrollState
                  ? topTypeList[1].activeColor
                  : topTypeList[topType].activeColor
                : undefined,
          }"
        ></div>
        {{ $t(item.name) }}
      </div>
      <div class="local">
        <a-dropdown
          arrow
          placement="bottom"
          :overlayStyle="{
            textAlign: 'center',
          }"
        >
          <a-space>
            <img
              :src="require(`@/assets/imgs/guo_${langObj[locale].img}.png`)"
            />
            <span class="txt" style="font-weight: 500">{{
              langObj[locale].txt
            }}</span>
            <DownOutlined />
          </a-space>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="handleChangeLocal('en')">
                English
              </a-menu-item>
              <a-menu-item @click="handleChangeLocal('zh')">
                简体中文
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DownOutlined } from '@ant-design/icons-vue';
import { ref, onMounted, watchEffect, nextTick, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
import pageList from '@/utils/pageList';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
function handleChangeLocal(lang = 'zh') {
  localStorage.setItem('calendar-lang', lang);
  locale.value = lang;
}
const langObj = {
  en: {
    img: 2,
    txt: 'English',
  },
  zh: {
    img: 1,
    txt: '简体中文',
  },
};
function handleChangePage(path = 'home') {
  document.querySelector(`#href_${path}`).scrollIntoView({
    behavior: 'smooth',
  });
}
const topType = ref(0); // 首页展示 0:透明,1:白色
const topTypeList = {
  0: {
    backgroundColor: 'transparent',
    color: '#fff',
    activeColor: '#fff',
  },
  1: {
    backgroundColor: '#fff',
    color: '#333333',
    activeColor: '#F44C1C',
  },
};
const scrollState = ref(false); // 是否滚动
watchEffect(() => {
  if (['/home'].includes(route.path)) {
    topType.value = 0;
  } else {
    topType.value = 1;
  }
});
const pageActive = ref('home');
const navbarLeft = ref(-window.scrollX);
let timer;
onMounted(() => {
  nextTick(() => {
    const arr = [];
    pageList.forEach((i) => {
      const dom = document.querySelector(`#href_${i.pathPc}`);
      if (dom) {
        arr.push(dom.getBoundingClientRect().top + window.scrollY);
      }
    });
    window.addEventListener('scroll', function () {
      scrollState.value = window.scrollY === 0 ? false : true;
      const y = window.scrollY;
      if (y >= arr[0] && y < arr[1]) {
        pageActive.value = 'home';
      } else if (y >= arr[1] && y < arr[2]) {
        pageActive.value = 'product';
      } else if (y >= arr[2] && y < arr[3]) {
        pageActive.value = 'team';
      } else if (y >= arr[3] && y < arr[4]) {
        pageActive.value = 'recruitment';
      } else {
        pageActive.value = 'contacts';
      }
    });
    window.addEventListener('scroll', function () {
      if (navbarLeft.value !== -window.scrollX) {
        navbarLeft.value = -window.scrollX;
      }
    });
  });
});
onUnmounted(() => {
  clearInterval(timer);
});
</script>
<style scoped lang="less">
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1350px;
  height: 95px;
  z-index: 100;
  transform: translateZ(100px);
  padding: 0 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    width: 102px;
    cursor: pointer;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .right > div + div {
    margin-left: 110px;
  }
  .page_item {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .page_item_active {
    font-size: 20px;
    font-weight: 500;
    position: relative;
  }
  .page_item_border {
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
  .local {
    cursor: pointer;
    img {
      width: 26px;
    }
    .txt {
      font-size: 16px;
    }
  }
}
</style>
